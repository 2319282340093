import { ImageObject, Organization } from 'schema-dts'

const PUBLIC_NAME = process.env.NEXT_PUBLIC_APP_NAME_NET
const PUBLIC_URL = process.env.NEXT_PUBLIC_APP_ROOT_NET
export const logo: ImageObject = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '@type': 'ImageObject',
    url: PUBLIC_URL,
    contentUrl: `${PUBLIC_URL}/images/nethr-logo.png`,
    width: '512px',
    height: '512px'
}
export const organization: Organization = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '@type': 'Organization',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '@id': '#net-hr-org',
    name: PUBLIC_NAME,
    url: PUBLIC_URL,
    // @TODO: input correct values for rtl
    sameAs: [
        'https://www.facebook.com/net.hr',
        'https://x.com/nethr',
        'https://www.instagram.com/net.hr_/',
        'https://www.tiktok.com/@net.hr'
    ],
    logo
}
